import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { GanttChartContext } from './gantt-chart.context';
import { ChartItem, DayProps, GanttDraggableItemRef, GanttItem } from '../types';
import {
  differenceInDays,
  getParentEndDate,
  getFullDatesRange,
  getParentStartDate,
  getEarliestDate,
  getLatestDate,
} from '../utils/date-utils';

type GanttChartProviderProps = {
  items: GanttItem[];
};

export const GanttChartProvider: React.FC<PropsWithChildren<GanttChartProviderProps>> = ({
  items: initialItems,
  children,
}) => {
  const currentStripRef = useRef<HTMLDivElement | null>(null);
  const resizingLayerRef = useRef<HTMLDivElement | null>(null);
  const currentItem = useRef<ChartItem | null>(null);
  const chartStartDate = useRef<Date>();
  const chartEndDate = useRef<Date>();
  const datesRangeByMonths = useRef<{ [key: string]: DayProps[] }>();
  const datesFullRange = useRef<DayProps[]>();
  const resizingSide = useRef<'left' | 'right' | undefined>();
  const resizeStartOffset = useRef<number>(0);
  const todayIndex = useRef<number | undefined>();
  const dateTrackerLabelRef = useRef<HTMLDivElement | null>(null);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const stripIndicatorsListRef = useRef<(HTMLDivElement | null)[]>([]);

  const [items, setItems] = useState<ChartItem[]>([]);

  useEffect(() => {
    let normalizedItems: ChartItem[] = [];
    const dates: Date[] = [new Date()];

    initialItems.forEach((item) => {
      const { subItems, ...rest } = item;

      if (subItems) {
        const startDate = getParentStartDate(subItems);
        const dueDate = getParentEndDate(subItems);

        if (startDate) {
          dates.push(startDate);
        }

        if (dueDate) {
          dates.push(dueDate);
        }

        const itemWithDates = {
          ...rest,
          startDate,
          dueDate,
        };

        const subItemsWithParentId = subItems.map((subItem) => ({
          ...subItem,
          parentId: item.id,
        }));

        normalizedItems.push(itemWithDates);
        normalizedItems = normalizedItems.concat(subItemsWithParentId);

        return itemWithDates;
      }

      normalizedItems.push(item);
      return item;
    });

    const earliestDate = getEarliestDate(dates);
    const latestDate = getLatestDate(dates);

    if (!earliestDate || !latestDate) {
      return;
    }

    const dateRangeProps = !datesFullRange.current && getFullDatesRange(earliestDate, latestDate);
    todayIndex.current = dateRangeProps ? dateRangeProps.todayIndex : todayIndex.current;

    datesRangeByMonths.current = dateRangeProps
      ? dateRangeProps.byMonth
      : datesRangeByMonths.current;
    datesFullRange.current = dateRangeProps ? dateRangeProps.fullRange : datesFullRange.current;

    if (!datesFullRange.current) {
      return;
    }

    const lastIndex = datesFullRange.current.length - 1;
    chartStartDate.current = datesFullRange.current[0].date;
    chartEndDate.current = datesFullRange.current[lastIndex].date;

    const itemsWithIndices = normalizedItems.map((item) => {
      if (!item.startDate && !item.dueDate) {
        return item;
      }

      const startDateIndex = differenceInDays(
        chartStartDate.current!,
        (item.startDate || item.dueDate)!,
      );

      const positionLeft = startDateIndex * 48 + 48;

      const durationInDays =
        item.dueDate && item.startDate ? differenceInDays(item.dueDate, item.startDate) : 0;
      return { ...item, startDateIndex, durationInDays: Math.abs(durationInDays), positionLeft };
    });

    setItems((prevItems) => {
      if (!prevItems?.length) {
        return itemsWithIndices;
      }

      const mergedItems = prevItems?.map((prevItem, index) => ({
        ...prevItem,
        ...itemsWithIndices[index],
      }));

      return mergedItems;
    });
  }, [initialItems]);

  const setCurrentStrip = (node: HTMLDivElement | null) => {
    currentStripRef.current = node;
  };

  const setCurrentItem = (item: ChartItem | null) => {
    currentItem.current = item;
  };

  const setResizingSide = (side?: 'left' | 'right' | undefined) => {
    resizingSide.current = side;
  };

  const setResizeStartOffset = (startXOffset?: number) => {
    resizeStartOffset.current = startXOffset || 0;
  };

  const handleItemsCollapse = (parentId: string, isCollapsed?: boolean) => {
    setItems((currItems) => {
      const newItems = currItems?.map((item) => {
        if ([item.parentId].includes(parentId)) {
          return { ...item, isCollapsed };
        }

        return item;
      });

      return newItems;
    });
  };

  return (
    <GanttChartContext.Provider
      value={{
        items,
        setItems,
        setCurrentStrip,
        currentStripRef,
        currentItem,
        setCurrentItem,
        resizeStartOffset,
        setResizeStartOffset,
        resizingSide,
        setResizingSide,
        chartStartDate: chartStartDate.current,
        chartEndDate: chartEndDate.current,
        datesRangeByMonths: datesRangeByMonths.current,
        datesFullRange: datesFullRange.current,
        resizingLayerRef,
        handleItemsCollapse,
        dateTrackerLabelRef,
        sidebarRef,
        stripIndicatorsListRef,
        todayIndex: todayIndex.current,
      }}
    >
      {children}
    </GanttChartContext.Provider>
  );
};
