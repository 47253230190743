import React, { useContext, useLayoutEffect, useRef } from 'react';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { GanttChartContext } from '../context/gantt-chart.context';
import { formatDate } from '../../../lib/utils/DateHelper';
import { dateShort2 } from '../../../assets/contants/dates-fns';
import { handleScrollToStrip } from '../utils/utils';

export type StripsIndicatorsLayerProps = {};

export const StripsIndicatorsLayer: React.FC<StripsIndicatorsLayerProps> = () => {
  const { items, stripIndicatorsListRef, sidebarRef } = useContext(GanttChartContext);
  const layerRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const parseDatesLabel = (startDate?: Date, dueDate?: Date) => {
    if (!startDate && !dueDate) {
      return;
    } else if (startDate && dueDate) {
      const formattedStartDate = formatDate(new Date(startDate).toISOString(), dateShort2);
      const formattedDueDate = formatDate(new Date(dueDate).toISOString(), dateShort2);

      return `${formattedStartDate} - ${formattedDueDate}`;
    }

    const formattedDate = formatDate(new Date((startDate || dueDate)!).toISOString(), dateShort2);

    return formattedDate;
  };

  useLayoutEffect(() => {
    if (layerRef.current && sidebarRef?.current) {
      layerRef.current.style.width = `calc(100% - ${sidebarRef.current.clientWidth + 16}px)`;
    }
  }, [sidebarRef]);

  const handleWheelCapture = (e: React.WheelEvent<HTMLDivElement>, index: number) => {
    const stripIndicatorElement = stripIndicatorsListRef?.current?.[index];
    if (!stripIndicatorElement) {
      return;
    }

    stripIndicatorElement.style.pointerEvents = 'none';

    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      stripIndicatorElement.style.pointerEvents = 'auto';
    }, 50);
  };

  const filteredItems = items.filter((strip) => !strip.isCollapsed);

  return (
    <Container ref={layerRef}>
      {filteredItems?.map((item, index) => {
        const formattedDates = parseDatesLabel(item.startDate, item.dueDate);

        return (
          <ArrowIndicator
            ref={(node) => {
              if (stripIndicatorsListRef?.current) {
                stripIndicatorsListRef.current[index] = node;
              }
            }}
            key={item.id}
            style={{
              gridRow: `${index + 1}`,
            }}
            onClick={() => handleScrollToStrip(item.id)}
            onWheelCapture={(e) => handleWheelCapture(e, index)}
          >
            <ArrowLeftIcon color="var(--color-texts-high-contrast)" />

            <Content>
              <Name>{item.name}</Name>

              <Text>{formattedDates}</Text>
            </Content>
          </ArrowIndicator>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fill, 4rem);
  row-gap: 0.4rem;
  position: absolute;
  right: 0.8rem;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 10;
  justify-items: start;
  align-items: center;
`;

const ArrowIndicator = styled.div`
  display: none;
  pointer-events: auto;
  gap: 0.4rem;
  min-height: 2.4rem;
  max-height: 3.4rem;
  background-color: transparent;
  color: var(--color-texts-middle-contrast);
  border-radius: var(--border-radius-small);
  padding: 0.4rem;
  cursor: pointer;
  width: fit-content;
  max-width: 12rem;
  justify-content: center;
  align-items: center;

  & svg {
    min-width: fit-content;
  }

  &:hover {
    background-color: var(--color-surfaces-bg-elevation-4);

    & p {
      color: var(--color-texts-high-contrast);
    }
  }
`;

const Text = styled.p`
  font-size: 1.2rem;
  font-weight: 400;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Name = styled(Text)`
  display: none;
  color: var(--color-texts-high-contrast);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  min-width: 0;
`;
