import { DateTime } from 'luxon';

import { i18n } from '../../../app/i18n';

import {
  Months,
  type DayProps,
  type Days,
  type GanttItem,
  type WeekDays,
  type Years,
} from '../types';

const END_OFFSET = 6; // months
const START_OFFSET = -6; // months
const WEEKEND_DAYS = ['Sat', 'Sun']; // day short name eg. Sun, Mon, Tue...
const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

export const isToday = (date: Date) => {
  const today = new Date();

  return areDatesEqual(date, today);
};

export const areDatesEqual = (date1: Date, date2: Date) => {
  const d1 = new Date(Date.UTC(date1.getUTCFullYear(), date1.getUTCMonth(), date1.getUTCDate()));
  const d2 = new Date(Date.UTC(date2.getUTCFullYear(), date2.getUTCMonth(), date2.getUTCDate()));

  return d1.getTime() === d2.getTime();
};

export type FullDatesRange = ReturnType<typeof getFullDatesRange>;

export type getFullDatesRange2ReturnType = {
  fullRange: DayProps[];
  byMonth?: { [key: string]: DayProps[] };
  todayIndex?: number;
};

export const getFullDatesRange: (start: Date, end: Date) => getFullDatesRange2ReturnType = (
  start,
  end,
) => {
  const dates = [];
  const sortedByMonth: getFullDatesRange2ReturnType['byMonth'] = {};

  const startDate = new Date(start);
  startDate.setMonth(startDate.getMonth() + START_OFFSET);
  startDate.setDate(1);
  const endDate = new Date(end);
  endDate.setMonth(endDate.getMonth() + END_OFFSET);
  let todayIndex = undefined;

  let trackingIndex = 0;

  for (const dt = new Date(startDate); dt <= endDate; dt.setDate(dt.getDate() + 1)) {
    const year = dt.getFullYear().toString() as Years;
    const month = dt.getMonth() as keyof typeof Months;
    const numericDay = dt.getDate().toString() as Days;
    const weekDay = dt.toLocaleString('default', { weekday: 'short' }) as WeekDays;

    const dateIsToday = todayIndex !== undefined ? false : isToday(dt);
    const isWeekend = !!WEEKEND_DAYS.includes(weekDay);

    if (dateIsToday) {
      todayIndex = trackingIndex;
    }

    const dateObject = {
      date: new Date(dt),
      day: numericDay,
      month,
      year,
      weekDay,
      isToday: dateIsToday,
      isWeekend,
    };

    const key = `${Months[month]}-${year}`;

    if (sortedByMonth[key]) {
      sortedByMonth[key].push(dateObject);
    } else {
      sortedByMonth[key] = [dateObject];
    }

    dates.push(dateObject);
    trackingIndex++;
  }

  return { fullRange: dates, byMonth: sortedByMonth, todayIndex };
};

export const getParentStartDate = (items: GanttItem[]) => {
  if (!items.length) {
    return;
  }

  return items.reduce((acc: Date | undefined, item: GanttItem) => {
    if (!item.startDate && !item.dueDate) {
      return acc;
    }

    if (!item.startDate) {
      return !acc || item.dueDate! < acc ? item.dueDate : acc;
    }

    if (!item.dueDate) {
      return !acc || item.startDate! < acc ? item.startDate : acc;
    }

    const earliestItemDate = Math.min(item.startDate.getTime(), item.dueDate.getTime());
    const earliestDate = acc ? Math.min(acc.getTime(), earliestItemDate) : earliestItemDate;

    return new Date(earliestDate);
  }, undefined);
};

export const getParentEndDate = (items: GanttItem[]) => {
  if (!items.length) {
    return;
  }

  return items.reduce((acc: Date | undefined, item: GanttItem) => {
    if (!item.startDate && !item.dueDate) {
      return acc;
    }

    if (!item.dueDate) {
      return !acc || item.startDate! > acc ? item.startDate : acc;
    }

    if (!item.startDate) {
      return !acc || item.dueDate! > acc ? item.dueDate : acc;
    }

    const latestItemDate = Math.max(item.startDate.getTime(), item.dueDate.getTime());
    const latestDate = acc ? Math.max(acc.getTime(), latestItemDate) : latestItemDate;

    return new Date(latestDate);
  }, undefined);
};

export const getEarliestDate = (dates: Date[]) => {
  if (!dates.length) {
    return;
  }

  return dates.reduce((earliest, date) => (date < earliest ? date : earliest), dates[0]);
};

export const getLatestDate = (dates: Date[]) => {
  if (!dates.length) {
    return;
  }

  return dates.reduce((latest, date) => (date > latest ? date : latest), dates[0]);
};

export const differenceInDays = (date1: Date, date2: Date) => {
  const differenceInMilliseconds = date2.getTime() - date1.getTime();

  const differenceInDays = differenceInMilliseconds / MILLISECONDS_PER_DAY;

  return Math.round(differenceInDays);
};

export const getDuration = (start: Date, end: Date, daysOffset: number = 0) => {
  const startDate = DateTime.fromJSDate(
    new Date(new Date(start).setDate(start.getDate() - daysOffset)),
  );
  const endDate = DateTime.fromJSDate(end);

  const duration = endDate.diff(startDate, ['years', 'months', 'days']);

  const { days, months, years } = duration.toObject();
  const roundedDays = Math.ceil(days || 0);

  const roundedDuration = {
    years: years || 0,
    months: months || 0,
    days: roundedDays || 0,
  };

  return roundedDuration;
};

export const formatDuration = ({ days, months, years }: ReturnType<typeof getDuration>) => {
  const t = i18n.t;
  const parts = [];

  if (years! > 0) {
    const yearsText = t('common:year', { count: years });
    parts.push(yearsText);
  }

  if (months! > 0) {
    const monthsText = t('common:month', { count: months });
    parts.push(monthsText);
  }

  if (days > 0) {
    const daysText = t('common:day', { count: days });
    parts.push(daysText);
  }

  if (parts.length === 0) {
    return '0 days';
  }

  if (parts.length === 1) {
    return parts[0];
  }

  const lastPart = parts.pop();
  return `${parts.join(', ')} and ${lastPart}`;
};
