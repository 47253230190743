import React, { useCallback, useContext, useRef } from 'react';
import styled from 'styled-components';
import { useDroppable } from '@dnd-kit/core';

import { GanttChartContext } from '../context/gantt-chart.context';
import { DraggableStrip } from './draggable-strip';
import { ParentStrip } from './parent-strip';
import { EmptyChartRow } from './empty-chart-row';
import { dateShort2 } from '../../../assets/contants/dates-fns';
import { formatDate } from '../../../lib/utils/DateHelper';

const weekColorMap = {
  weekend: 'var(--color-surfaces-bg-elevation-1)',
  weekday: 'transparent',
};

const weekDaysColors = [
  weekColorMap.weekend,
  weekColorMap.weekday,
  weekColorMap.weekday,
  weekColorMap.weekday,
  weekColorMap.weekday,
  weekColorMap.weekday,
  weekColorMap.weekend,
]; // Sun is index 0

const getChartGradient = (startDayIndex = 0) => {
  const gradientColors = [];

  for (let i = 0; i < 7; i++) {
    const currentDay = startDayIndex + i;

    let color = 'transparent';
    const index = currentDay < 7 ? startDayIndex + i : currentDay - 7;

    color = weekDaysColors[index];
    gradientColors.push({ color, index });
  }

  const gradientString = gradientColors
    .map(({ color, index: colorIndex }, index) => {
      const startIndex = index * 48 + 1;
      const endIndex = (index + 1) * 48 - 1;

      const endBorderColor =
        colorIndex === 0 ? 'var(--color-surfaces-bg-elevation-4)' : 'transparent';
      const startBorderColor =
        colorIndex === 1 ? 'var(--color-surfaces-bg-elevation-4)' : 'transparent';

      return `${startBorderColor} ${0}px 1px, ${color} ${startIndex}px ${endIndex}px, ${endBorderColor} ${endIndex}px ${
        endIndex + 1
      }px`;
    })
    .join(', ');

  return gradientString;
};

export const Chart = React.forwardRef<HTMLDivElement, any>((_, ref) => {
  const {
    resizingLayerRef,
    dateTrackerLabelRef,
    chartStartDate,
    datesFullRange,
    items,
    resizingSide,
    currentStripRef,
    resizeStartOffset,
    setResizeStartOffset,
    setResizingSide,
    setCurrentStrip,
    currentItem,
    setCurrentItem,
    todayIndex,
  } = useContext(GanttChartContext);

  const dateTrackerRef = useRef<HTMLDivElement>(null);

  const daysDiffRef = useRef<number>(0);

  const { setNodeRef } = useDroppable({
    id: 'chart',
  });

  const setChartGradientRef = (node: HTMLDivElement) => {
    if (!node || !chartStartDate) {
      return null;
    }

    if (ref) {
      if (typeof ref === 'function') {
        ref(node);
      } else {
        ref.current = node;
      }
    }

    const startDay = chartStartDate.getDay();

    const gradientString = getChartGradient(startDay);

    node.style.background = `
    repeating-linear-gradient(90deg, ${gradientString}),
    repeating-linear-gradient(
      to bottom,
      transparent 0rem 0.3rem,
      var(--color-surfaces-bg-elevation-2) 0.3rem 0.5rem
    ), 
    repeating-linear-gradient(
      90deg,
      transparent 0rem 4.7rem,
      var(--color-surfaces-bg-elevation-4) 4.7rem 4.8rem
    )`;
  };

  const handleMouseUp = useCallback(() => {
    const item = currentItem?.current;
    const side = resizingSide?.current;

    if (!item || !side) {
      return;
    }

    setResizeStartOffset?.(0);
    setCurrentItem?.(null);
    setCurrentStrip?.(null);
    setResizingSide?.(undefined);

    if (!resizingLayerRef?.current) {
      return;
    }
    resizingLayerRef.current.style.pointerEvents = 'none';

    if (!daysDiffRef.current) {
      return;
    }

    if (side === 'left') {
      const startDate = new Date((item.startDate || item.dueDate)!);

      startDate.setDate(startDate.getDate() - daysDiffRef.current);
      daysDiffRef.current = 0;

      item.onItemUpdate?.({ startDate });
    } else if (side === 'right') {
      const dueDate = new Date((item.dueDate || item.startDate)!);

      dueDate.setDate(dueDate.getDate() + daysDiffRef.current);
      daysDiffRef.current = 0;

      item.onItemUpdate?.({ dueDate });
    }
  }, [
    currentItem,
    resizingLayerRef,
    resizingSide,
    setCurrentItem,
    setCurrentStrip,
    setResizeStartOffset,
    setResizingSide,
  ]);

  const adjustItemName = (strip: HTMLElement, stripSpan: number) => {
    const stripTitle = strip.querySelector('#strip-title') as HTMLParagraphElement;

    if (stripSpan <= 1) {
      strip.style.gridColumnEnd = `span 1`;

      if (stripTitle) {
        stripTitle.style.transform = 'translateX(4.8rem)';
        stripTitle.style.whiteSpace = 'noWrap';
      }
    } else {
      //TODO:  check later for enhancement, instead of transforming on every mouse move
      if (stripTitle) {
        stripTitle.style.transform = 'translateX(0)';
      }
    }
  };

  const handleGuideLineMove = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      const gridLayer = resizingLayerRef?.current;

      if (!gridLayer) {
        return;
      }

      const gridRect = gridLayer.getBoundingClientRect();
      const mouseX = event.clientX - gridRect.left;
      const columnIndex = Math.floor(mouseX / 48);

      if (dateTrackerRef.current) {
        dateTrackerRef.current.style.left = `${mouseX}px`;
      }

      if (dateTrackerLabelRef?.current) {
        const hoveredDate = datesFullRange?.[columnIndex]?.date;
        const tooltipDate = hoveredDate ? formatDate(hoveredDate.toISOString(), dateShort2) : '';

        dateTrackerLabelRef.current.style.left = `${mouseX}px`;
        dateTrackerLabelRef.current.innerHTML = tooltipDate;
      }
    },
    [dateTrackerLabelRef, datesFullRange, resizingLayerRef],
  );

  const handleMouseLeave = () => {
    if (dateTrackerRef.current) {
      dateTrackerRef.current.style.display = 'none';
    }

    if (dateTrackerLabelRef?.current) {
      dateTrackerLabelRef.current.style.display = 'none';
    }
  };

  const handleMouseEnter = () => {
    if (dateTrackerRef.current) {
      dateTrackerRef.current.style.display = 'block';
    }

    if (dateTrackerLabelRef?.current) {
      dateTrackerLabelRef.current.style.display = 'block';
    }
  };

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      const strip = currentStripRef?.current?.parentElement;
      const side = resizingSide?.current;

      if (!side || !strip) return;

      const currentStripStart = parseInt(strip.style.gridColumnStart, 10);
      const currentStripSpan = parseInt(strip.style.gridColumnEnd.replace('span', '').trim(), 10);

      const startX = resizeStartOffset?.current || 0;
      const diffX = event.clientX - startX;
      const snap = Math.floor(diffX / 48) * 48;

      let newSpan = currentStripSpan;
      let newStripStart = currentStripStart;
      const daysDiff = snap / 48;

      if (Math.abs(snap) >= 24) {
        if (side === 'left') {
          newSpan -= daysDiff;

          if (newSpan <= 0) {
            newSpan = 1;
            return;
          }

          newStripStart += daysDiff;
          daysDiffRef.current -= daysDiff;
        } else if (side === 'right') {
          newSpan += daysDiff;

          if (newSpan <= 0) {
            newSpan = 1;
            return;
          }
          daysDiffRef.current += daysDiff;
        }

        setResizeStartOffset?.(startX + snap);
        strip.style.gridColumnEnd = `span ${newSpan}`;
        strip.style.gridColumnStart = `${newStripStart}`;

        adjustItemName(strip, newSpan);
      }
    },
    [currentStripRef, resizeStartOffset, resizingSide, setResizeStartOffset],
  );

  const filteredItems = items.filter((strip) => !strip.isCollapsed);

  return (
    <Container
      ref={setChartGradientRef}
      onMouseMove={handleGuideLineMove}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      <DateVerticalGuideLine ref={dateTrackerRef} />

      <ChartContainer ref={setNodeRef}>
        <ResizingLayer
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          ref={resizingLayerRef}
        >
          <TodayVerticalGuide
            style={{
              gridColumnStart: (todayIndex || 0) + 1,
              gridColumnEnd: `span 1`,
              gridRowStart: 1,
              gridRowEnd: `span 1 ${filteredItems?.length}`,
            }}
          />

          {filteredItems.map((item, index) => {
            if (!item.parentId) {
              return (
                <ParentStrip
                  key={item.id}
                  item={item}
                  style={{
                    gridColumnStart: (item.startDateIndex || 0) + 1,
                    gridColumnEnd: `span ${(item.durationInDays || 0) + 1}`,
                    gridRowStart: index + 1,
                    gridRowEnd: `span 1`,
                  }}
                  index={index}
                />
              );
            }

            if (item.startDate || item.dueDate) {
              return (
                <ItemContainer
                  key={item.id}
                  id={`strip-${item.id}`}
                  style={{
                    gridColumnStart: (item.startDateIndex || 0) + 1,
                    gridColumnEnd: `span ${(item.durationInDays || 0) + 1}`,
                    gridRowStart: index + 1,
                    gridRowEnd: `span 1`,
                  }}
                >
                  <DraggableStrip item={item} index={index} />
                </ItemContainer>
              );
            } else {
              return (
                <EmptyChartRow
                  key={item.id}
                  item={item}
                  style={{
                    gridColumnStart: 1,
                    gridColumnEnd: `span ${datesFullRange?.length}`,
                    gridRowStart: index + 1,
                    gridRowEnd: `span 1`,
                  }}
                />
              );
            }
          })}
        </ResizingLayer>
      </ChartContainer>
    </Container>
  );
});

Chart.displayName = 'Chart';

const Container = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--color-surfaces-bg-elevation-2);
  min-width: fit-content;
`;

const ChartContainer = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  position: absolute;
  grid-template-columns: repeat(auto-fill, 4.8rem);
  grid-template-rows: repeat(auto-fill, 4rem);
  row-gap: 0.4rem;
  bottom: 0;
  left: 0;
`;

const ItemContainer = styled.div`
  position: relative;
  pointer-events: all;
`;

const ResizingLayer = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  position: absolute;
  grid-template-columns: repeat(auto-fill, 4.8rem);
  grid-template-rows: repeat(auto-fill, 4rem);
  row-gap: 0.4rem;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 3;
`;

const DateVerticalGuideLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 0.2rem;
  background-color: var(--color-grayscale-light-slate);
  pointer-events: none;
  transform: translateX(-50%);
  pointer-events: none;
`;

const TodayVerticalGuide = styled(DateVerticalGuideLine)`
  background-color: var(--color-shades-dark-raspberry);
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
`;
