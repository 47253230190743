import React, { useCallback, useContext, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { DndContextWrapper } from './components/dnd-context-wrapper';
import { Sidebar } from './components/sidebar';
import { Chart } from './components/chart';
import { ChartHeader } from './components/chart-header';
import { GanttChartContext } from './context/gantt-chart.context';
import { StripsIndicatorsLayer } from './components/strips-indicators-layer';

import type { GanttItem } from './types';

export type ChartWrapperProps = {
  items: GanttItem[];
};

export const ChartWrapper: React.FC<ChartWrapperProps> = ({ items }) => {
  const { datesRangeByMonths, dateTrackerLabelRef, sidebarRef } = useContext(GanttChartContext);

  const [chartWidth, setChartWidth] = useState<number>();
  const headerContainerRef = useRef<HTMLDivElement>(null);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (!chartRef?.current || !chartWidth) {
      return;
    }

    chartRef.current.style.width = `${chartWidth}px`;
  }, [chartWidth, items]);

  const headerCallbackRef = useCallback((node: HTMLDivElement) => {
    if (!node) {
      return null;
    }

    const headerRect = node.getBoundingClientRect();

    setChartWidth(headerRect.width);
  }, []);

  const handleHeaderScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (headerContainerRef?.current && chartContainerRef?.current) {
      if (chartContainerRef.current.scrollLeft !== headerContainerRef.current.scrollLeft)
        chartContainerRef.current.scrollLeft = headerContainerRef.current.scrollLeft;
    }
  };

  const handleChartScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (headerContainerRef?.current && chartContainerRef?.current) {
      headerContainerRef.current.scrollLeft = chartContainerRef.current.scrollLeft;
    }
  };

  return (
    <>
      <HeaderContainer ref={headerContainerRef} onScroll={handleHeaderScroll}>
        <DateTrackerLabel ref={dateTrackerLabelRef} />

        <ChartHeader ref={headerCallbackRef} datesByMonths={datesRangeByMonths} />
      </HeaderContainer>

      <Container>
        <Sidebar items={items} ref={sidebarRef} />

        <StripsIndicatorsLayer />

        <DndContextWrapper>
          <ChartContainer ref={chartContainerRef} onScroll={handleChartScroll}>
            <Chart ref={chartRef} />
          </ChartContainer>
        </DndContextWrapper>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: fit-content;
  position: relative;
  align-items: stretch;
  max-width: 100%;
  min-height: calc(100% - 6.2rem);
  margin-top: -3rem;
  background-color: var(--color-surfaces-bg-elevation-2);
`;

const ChartContainer = styled.div`
  min-width: 0;
  min-height: 100%;
  height: calc(100% + 0.6rem); // todo: find another solution to fix height and scrollbar
  width: 100%;
  margin-inline-start: -24rem;
  overflow-x: auto;
`;

const HeaderContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
  position: sticky;
  top: 0;
  min-height: 9.4rem;
  z-index: 11;
  pointer-events: none;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DateTrackerLabel = styled.div`
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  background-color: black;
  color: var(--color-grayscale-white);
  text-align: center;
  padding: 0.8rem 1rem;
  border-radius: 0.6rem;
  font-size: 1.2rem;
  font-weight: 600;
  z-index: var(--layer-tooltip);
  width: auto;
  white-space: nowrap;
  opacity: 1;
`;
